import { ReactChild, ReactNode, SyntheticEvent } from 'react';
import PrmPages from 'app/new_sidebar/pages/PrmPages';
import { Moment } from 'moment';

export type AppState = {
	mainViewNode: ReactNode;
	popupNode: ReactNode;
	isPopupClosable: boolean;
	currentURL: string[];
	headerSettings: {
		title: ReactNode;
		showHeaderSelector: boolean;
	};
	isNewSidebar: boolean;
	sidebarURLs: {
		kinoplan: string;
		ticket_system: string;
		tms: string;
		kinosite: string;
		cv: string;
		dashboard: string;
		ad_network: string;
		[PrmPages.SECTION_NAME]: string;
		videoposter: string;
		bar: string;
		crm: string;
	};
	pressedKeys: number[];
	isFetching: boolean;
	showOverlay: boolean;
	toasts: IToast[];
	error: string | Error;
	searchValue: string;
	topNotifications: {
		TMS: number;
		Monitoring: number;
		Videopanels: number;
		Kinokassa: number;
		Booking: number;
	};
	onboardingShownFeatures: string[];
	userAdvancedInfoVisibleDate: Moment | null;
}

export interface IToast {
	id?: string;
	subtitle?: ReactChild | string;
	title?: ReactChild | string;
	delay?: number;
	type?: 'success' | 'warning' | 'error';
	onClick?: (event: SyntheticEvent) => void;
}

export type InfoPopupProps = Omit<TKComponents.IInfoPopupProps, 'onCancel' | 'onConfirm'> & {
	content?: ReactNode;
	onCancel?: () => void;
	onConfirm?: () => void;
}

export const localeProps = PropTypes.shape({
	en: PropTypes.string,
	ru: PropTypes.string,
});

export const dropdownOption = PropTypes.shape({
	value: PropTypes.any,
	title: PropTypes.node,
	isDisabled: PropTypes.bool,
});
