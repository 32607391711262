import {
	highlightClass,
	OPEN_SALES_CODE,
	APPROVED_SEANCES_CODE,
	LOCKED_SEANCES_CODE,
	SUCCESS_CODE,
	NO_SUBSCRIPTION_CODE,
	SOLD_TICKETS_ON_PREMIERE_CODE,
	CANT_DELETE_UNAPPROVED_SEANCES,
} from 'app/schedule/scheduleConstants';
import ScheduleCopyingToasts from 'app/schedule/components/ScheduleCopyingToasts';
import {addErrorToast, addSuccessToast} from 'app/app/AppActions';
import {batchActions} from 'redux-batched-actions';
import {COLORS} from 'app/app/ColorsConstants';
import {doesSeanceHasSolds} from 'app/schedule/selectors/scheduleSeancesSelectors';

export const transformFormatsArrayToString = formatsArr => formatsArr.join('-');

const getContainer = () =>
	document.getElementById('schedule-seances-table-content') || document.getElementById('schedule-timeline-list');

export function changeHighlightForSeances({
	action = 'remove',
	voiceoverID = null,
	releaseID,
	formatsString,
	fromTimeline,
}) {
	const container = getContainer();

	if (!container) return;

	Array.prototype.forEach.call(
		fromTimeline
			? container.querySelectorAll(`[data-release-id="${releaseID}"]`)
			: container.querySelectorAll(`[data-release="${releaseID}_formats-${formatsString}"]`),
		seance => {
			const seanceVoiceoverID = +seance.dataset.voiceoverId || null;
			const seanceReleaseID = +seance.dataset.releaseId;

			if (fromTimeline || (seanceVoiceoverID === voiceoverID && seanceReleaseID === releaseID)) {
				seance.classList[action](highlightClass);
			}
		},
	);
}

export function clearHighlightForAllSeances() {
	const container = getContainer();

	if (!container) return;

	Array.prototype.forEach.call(
		container.querySelectorAll(`.${highlightClass}`),
		seance => seance.classList.remove(highlightClass),
	);
}

export const setSeanceSaleDefault = seance => (seance.hasOwnProperty('sale')
	? seance
	: ({
		...seance,
		sale: {
			free_amount: 0,
			inet_approved_count: 0,
			is_closed: false,
			is_close_online_sale: false,
			is_close_ordering: false,
			is_open: false,
			is_sold: false,
			price_max: 0,
			price_min: 0,
			reserved_count: 0,
			admin_reserved_count: 0,
			sales_count: 0,
			sold_count: 0,
			ucs_is_open: false,
			ucs_on_sale: true,
			updated: null,
		},
	}));

export const changeDateFormat = (dates, format = 'YYYY-MM-DD') => _.map(dates, day => moment(day).format(format));

const isSameDatePart = (startDate, endDate, value) => moment(startDate).isSame(endDate, value);

const changeMay = string => string.replace('й', 'я');

const formatDate = (value, format) => changeMay(`${moment(value).format(format)}`);

const formatDatesPeriod = ({startDate, endDate, format, splitter, isLastDate}) => changeMay(
	isLastDate
		? `${startDate.format(format)}${splitter}${endDate.format('D MMM YYYY')}`
		: `${startDate.format(format)}${splitter}${endDate.format(format)}`,
);

export function getDatesInString(dates) {
	const sortedDates = _.sortBy(dates, date => +moment(date));

	return _.reduce(sortedDates, (memo, value, index) => {
		const isLastDate = _.isUndefined(sortedDates[index + 1]);
		const next = moment(sortedDates[index + 1]);

		if (next.diff(value, 'days') > 1 || isLastDate) {
			if (_.isNull(memo.first)) {
				if (isLastDate) {
					memo.arr.push(formatDate(value, 'D MMM YYYY'));
				} else if (!isSameDatePart(moment(value), next, 'month')) {
					memo.arr.push(formatDate(value, 'D MMM'));
				} else {
					memo.arr.push(formatDate(value, 'D'));
				}
			} else {
				const startDate = moment(memo.first);
				const endDate = moment(value);

				if (!isSameDatePart(memo.first, value, 'year')) {
					memo.arr.push(`${startDate.format('D MMM YYYY')} - ${endDate.format('D MMM YYYY')}`);
				} else if (!isSameDatePart(memo.first, value, 'month')) {
					memo.arr.push(formatDatesPeriod({
						startDate,
						endDate,
						format: 'D MMM',
						splitter: ' - ',
						isLastDate,
					}));
				} else if (!isSameDatePart(endDate, next, 'month') && !isLastDate) {
					memo.arr.push(`${startDate.format('D')}-${endDate.format('D MMM')}`);
				} else {
					memo.arr.push(formatDatesPeriod({
						startDate,
						endDate,
						format: 'D',
						splitter: '-',
						isLastDate,
					}));
				}

				memo.first = null;
			}
		} else {
			memo.first = _.isNull(memo.first) ? value : memo.first;
		}

		return memo;
	}, {first: null, arr: []}).arr.join(', ');
}

export function getDatesRangeInString(dateStart, dateEnd) {
	const dateStartRange = moment(dateStart).isSame(dateEnd, 'month')
		? moment(dateStart).format('D')
		: moment(dateStart).format('D MMM ');

	const dateEndRange = moment(dateStart).isSame(dateEnd, 'month')
		? moment(dateEnd).format('DD MMM YYYY')
		: moment(dateEnd).format(' DD MMM YYYY');

	return moment(dateStart).isSame(dateEnd) ? moment(dateStart).format('DD MMM YYYY') : `${dateStartRange}-${dateEndRange}`;
}

export function getDayName(date) {
	let copyDayName = '';
	const dayDifference = moment().diff(date, 'days', true);
	if (dayDifference < 1 && dayDifference > 0) {
		copyDayName = i18n.t('common.today');
	} else if (dayDifference < 0 && dayDifference > -1) {
		copyDayName = i18n.t('common.tomorrow');
	} else {
		copyDayName = moment(date).format('DD MMM YYYY');
	}

	return copyDayName;
}

export function getRangeOfDates(dateStart, dateEnd, range = []) {
	if (dateStart > dateEnd) return range;

	return getRangeOfDates(moment(dateStart).add(1, 'day').format(), dateEnd, [...range, dateStart]);
}

export function areStatesEqualComparator(nextState, prevState, ...selectors) {
	return selectors.every(selector => selector(nextState) === selector(prevState));
}

export const getErrorText = errorCode => {
	switch (errorCode) {
		case OPEN_SALES_CODE:
		case SOLD_TICKETS_ON_PREMIERE_CODE:
		case CANT_DELETE_UNAPPROVED_SEANCES:
			return i18n.t('schedule:ScheduleWasNotCopied.Sales');
		case APPROVED_SEANCES_CODE: return i18n.t('schedule:ScheduleWasNotCopied.ApprovedSchedule');
		case LOCKED_SEANCES_CODE: return i18n.t('schedule:ScheduleWasNotCopied.LockedSeances');
		case NO_SUBSCRIPTION_CODE: return i18n.t('schedule:ScheduleWasNotCopied.HasNoSubscription');
		default: return null;
	}
};

export const getGroupedCinemasByCode = (response, cinemas) => (
	_.reduce(_.groupBy(response, 'code'), (memo, items, key) => {
		const cinemasInGroup = _.compact(_.map(items, item => _.find(cinemas, cinema => cinema.id === item.cinema_id)));
		if (cinemasInGroup.length) {
			memo[key] = cinemasInGroup;
		}

		return memo;
	}, {})
);

export const showCopyingToasts = (response, targetCinemas, dateStart, dateEnd, dispatch) => {
	const copiedCinemas = getGroupedCinemasByCode(response, targetCinemas);

	const showToasts = !_.isEmpty(copiedCinemas)
		? _.map(copiedCinemas, (value, status) => {
			if (+status !== SUCCESS_CODE) {
				return addErrorToast({
					subtitle: <ScheduleCopyingToasts
						dateStart={dateStart}
						dateEnd={dateEnd}
						targetCinemas={value}
						errorCode={+status}
					/>,
				});
			}

			return addSuccessToast({
				subtitle: <ScheduleCopyingToasts
					dateStart={dateStart}
					dateEnd={dateEnd}
					targetCinemas={value}
				/>,
			});
		})
		: [addErrorToast({
			subtitle: i18n.t('validations.ConnectionError'),
		})];

	dispatch(batchActions(showToasts));
};

export const sharpTrim = color => {
	if (color.substr(0, 1) === '#') {
		return color.slice(1);
	}

	return color;
};

export const getContrastColorText = color => {
	if (color.substr(0, 1) === '#') {
		const redValue = parseInt(color.substr(1, 2), 16);
		const greenValue = parseInt(color.substr(3, 2), 16);
		const blueValue = parseInt(color.substr(5, 2), 16);

		// Color brightness is determined by the following formula:
		// (Red value * 0.299) + (Green value * 0.587) + (Blue value * 0.114)
		// 125 this is the middle value between black and white

		return redValue * 0.299 + greenValue * 0.587 + blueValue * 0.114 >= 125 ? COLORS.gray900 : COLORS.white;
	}

	return color.r * 0.299 + color.g * 0.587 + color.b * 0.114 >= 125 ? COLORS.gray900 : COLORS.white;
};

export const doSeancesHaveSoldTickets = (groupedSeances, dateStart, shouldCheckAfter) => _.chain(groupedSeances)
	.filter((seances, date) => (shouldCheckAfter
		? moment(date).isAfter(dateStart)
		: moment(date).isSame(dateStart)))
	.flatten()
	.some(doesSeanceHasSolds)
	.value();
